import React, { useState, useEffect } from 'react';

import Meta from '../components/meta';

import { Link } from 'gatsby';

import { navigate } from 'gatsby';

import { useForm } from 'react-hook-form';

import ReCAPTCHA from 'react-google-recaptcha';

import Contents from '../components/contents';

const Contact = (props) =>{



    // todo 本番時こちらを有効
    const [captcha, setCaptcha] = useState(false);
    // const [captcha, setCaptcha] = useState(true);
    const [policy, setPolicy] = useState(false);

    useEffect(() => {

        const sign = document.querySelector('.js-sign');

        if(captcha === true && policy === true){
            sign.classList.remove("is-lock");
        }else{
            sign.classList.add("is-lock");
        }    

    });



    const checkAgree = () =>{
        const checkButton = document.querySelector('.js-form-sign');

        if(checkButton.checked){
            checkButton.parentNode.classList.add("is-check");
            setPolicy(true);
        }else{
            checkButton.parentNode.classList.remove("is-check");
            setPolicy(false);
        }
    }

    const onChange = (e) =>{
        const getCaptcha = e;
        
        if(getCaptcha){
            setCaptcha(true);
        }
    }



    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: 'onBlur',
    });
    
    const postFetch = async () => {

        // console.log(data);

        // console.log(errors);

        let success = false;

        document.querySelector('.js-load').classList.add('is-load')

        const url = process.env.CONTACT_URL
    
        const formData = new FormData(document.getElementById('form'));
    
        var object = {};
        formData.forEach(function(value, key){
            object[key] = value;
        });

        // console.log(object)

        await fetch(url, {
            method: 'post',
            cache: 'no-cache',
            mode: 'cors',
            headers:{
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(object),
        }).then(response => {
            // console.log(response)

            if(!response.ok) {
                console.log('error!');

                document.querySelector('.js-load').classList.remove('is-load')
            } 

            if(response.ok){
                success = true;

                sessionStorage.setItem('iqd','true')

                navigate(`/thankyou/`,
                {
                    state: { success:success },
                    replace: true,
                }
                );
            }

            // console.log('ok!');

			return response.json();
        }).then(data  => {
            // console.log(data);
        }).catch(error => {
            // console.log(error);
        });
    };

    return(
        <>

            <Meta
                title="お問い合わせ"
                description="お問い合わせ。御社との出会いを楽しみにしております。この度は弊社にご興味を持っていただき、大変ありがとうございます。 ご相談したいこと。ご質問したいこと。お気軽にお問い合わせください。"
            />

            <Contents position="contact">

                <div className="c-sub-header">
                    <em className="c-sub-header--eng u-josef">
                    CONTACT
                    </em>

                    <h1 className="c-sub-header--title">
                    お問い合わせ
                    </h1>
                </div>



                <div className="p-inquiry">
                    <div className="p-inquiry-inner">

                        <form id="form" onSubmit={handleSubmit(postFetch)}>
                            <div className="p-inquiry-cmt">
                                <p className="p-inquiry-cmt--text">
                                この度は弊社にご興味を持っていただき、大変ありがとうございます。<br />
                                ご相談したいこと。ご質問したいこと。お気軽にお問い合わせください。
                                </p>

                                <br />

                                <p className="p-inquiry-cmt--text is-attr">
                                ※電話やメール、当サイトのフォームを利用した当社への売り込みの一切をお断りしています。<br />
                                状況によりしかるべき措置を取らせていただきます。
                                </p>
                            </div>

                            <div className="p-inquiry-form">
                                <div className="p-inquiry-form-title">
                                    <p className="p-inquiry-form-title--text">
                                    企業名
                                    </p>

                                    <div className="p-inquiry-form-icon">
                                        <p className="p-inquiry-form-icon--text">
                                        任意
                                        </p>
                                    </div>
                                </div>

                                <div className="p-inquiry-form-item is-middle">
<input type="text" className="c-input" size="60" value={props.company} placeholder="企業名を入力してください"
{...register("company", {

})}
/>
                                </div>
                            </div>

                            <div className="p-inquiry-form">
                                <div className="p-inquiry-form-title">
                                    <p className="p-inquiry-form-title--text">
                                    部署名
                                    </p>

                                    <div className="p-inquiry-form-icon">
                                        <p className="p-inquiry-form-icon--text">
                                        任意
                                        </p>
                                    </div>
                                </div>

                                <div className="p-inquiry-form-item is-middle">
<input type="text" className="c-input" size="60" value={props.position} placeholder="部署名を入力してください"
{...register("position", {

})}
/>
                                </div>
                            </div>

                            <div className="p-inquiry-form">
                                <div className="p-inquiry-form-title">
                                    <p className="p-inquiry-form-title--text">
                                    お名前
                                    </p>
                                </div>

                                <div className="p-inquiry-form-item is-short">
<input type="text" className={`c-input ${errors.name && "is-error"}`} size="60" value={props.name} placeholder="お名前を入力してください"
{...register("name", {
    required:'必須入力です',
})}
/>
{(() =>{
if(errors.name){
    return(
        <>
            <p className="error">
            {errors.name.message}
            </p>
        </>
    )
}
})()}
                                </div>
                            </div>

                            <div className="p-inquiry-form">
                                <div className="p-inquiry-form-title">
                                    <p className="p-inquiry-form-title--text">
                                    メールアドレス
                                    </p>
                                </div>

                                <div className="p-inquiry-form-item is-long">
<input type="text" className={`c-input ${errors.email && 'is-error'}`} size="60" value={props.email} placeholder="メールアドレスを入力してください"
{...register("email", {
    required:'必須入力です',
    pattern: {
        value:/^\S+@\S+$/i,
        message:'メールアドレスの形式ではありません',
    }
})}
/>
{(() =>{
if(errors.email){
    return(
        <>
            <p className="error">
            {errors.email.message}
            </p>
        </>
    )
}
})()}

                                </div>
                            </div>

                            <div className="p-inquiry-form">
                                <div className="p-inquiry-form-title">
                                    <p className="p-inquiry-form-title--text">
                                    電話番号
                                    </p>
                                </div>

                                <div className="p-inquiry-form-item is-short">
<input type="text" className={`c-input ${errors.tel && 'is-error'}`} size="60" value={props.tel} placeholder="電話番号を入力してください"
{...register("tel", {
    required:'必須入力です',
    pattern: {
        value:/^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/,
        message:'電話番号の形式ではありません',
    },
})}
/>
{(() =>{
if(errors.tel){
    return(
        <>
            <p className="error">
            {errors.tel.message}
            </p>
        </>
    )
}
})()}

                                </div>
                            </div>

                            <div className="p-inquiry-form">
                                <div className="p-inquiry-form-title">
                                    <p className="p-inquiry-form-title--text">
                                    お問い合わせ
                                    </p>
                                </div>

                                <div className="p-inquiry-form-item">
<textarea name="comment" className={`c-textarea ${errors.comment && 'is-error'}`} cols="50" rows="5" placeholder="お問い合わせ内容を詳しくご記入ください。"
{...register("comment", {
    required:'必須入力です',
})}
/>
{(() =>{
if(errors.comment){
    return(
        <>
            <p className="error">
            {errors.comment.message}
            </p>
        </>
    )
}
})()}

                                </div>
                            </div>

                            <div className="p-inquiry-form-policy">
                                <p className="p-inquiry-form-policy--title">
                                個人情報の取り扱いについて
                                </p>

                                <p className="p-inquiry-form-policy--text">
                                個人情報のお取り扱い方針については<Link to="/policy" title="個人情報の取り扱い" className="c-text--link">こちら</Link>をご覧ください
                                </p>

                                <p className="p-inquiry-form-policy--text">
                                ご入力項目に不備等の項目があった場合は、お問い合わせに対してお答えできない場合、又はご連絡できない場合がございますので予めご了承ください。
                                </p>

                                <div className="p-inquiry-form-policy__check">
                                    <label className="c-chk p-inquiry-form-policy__check-wrap">
                                        <input className="c-chk__box js-form-sign" type="checkbox" onClick={checkAgree} />
                                        <span className="c-chk__label">個人情報の取り扱いについて同意する</span>
                                    </label>

                                    <ReCAPTCHA
                                        className="p-inquiry-form-recaptcha"
                                        size="compact"
                                        sitekey="6LdHH9oZAAAAAJulNNccYmLs6biCDb1e0uaW9pLV"
                                        onChange={onChange}
                                    />
                                </div>
                            </div>

                            <div className="p-inquiry-form-cmd">
                                <div className="is-lock js-sign">
                                    <p className="p-inquiry-form-cmd--text">個人情報の取り扱いについての同意、CAPTCHA認証のうえ、お問い合わせください</p>

                                    <div className="c-submit-wrap js-load">
                                        <input type="submit" name="submitConfirm" value="確認" className="c-submit" />
                                    </div>

                                </div>
                            </div>

                        </form>

                    </div>
                </div>

            </Contents>

        </>
    )
}

export default Contact;


